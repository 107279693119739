import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';

@Component({
    selector: 'nemo-export',
    templateUrl: './export.component.html',
    styleUrls: ['./export.component.css'],
    standalone: true,
    imports: [ClipboardModule]
})
export class ExportComponent{

  @Input() url: string;
  @Input() title = 'Export';

  constructor(public activeModal: NgbActiveModal) { }

}
