import { Component, Input, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'nemo-basepage',
    templateUrl: './basepage.component.html',
    styleUrls: ['./basepage.component.css'],
    standalone: true,
    imports: [NgIf]
})
export class BasepageComponent {
  @Input() icon: string;
  @Input() title: string;
  @Input() subtitle: string;

  constructor() { }

}
