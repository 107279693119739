import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Observation, LabelledObservation, EvaluationScale } from '@app/model';
import { MediaUploadModalComponent } from '@app/shared/components/media-upload-modal/media-upload-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EvaluationComponent } from '../../../shared/components/evaluation/evaluation.component';
import { AutoEvalutationStateComponent } from '../../../shared/components/auto-evalutation-state/auto-evalutation-state.component';
import { MediaPreviewComponent } from '../../../shared/components/media-preview/media-preview.component';
import { FormsModule } from '@angular/forms';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'nemo-observation',
    templateUrl: './observation.component.html',
    styleUrls: ['./observation.component.css'],
    standalone: true,
    imports: [NgIf, FormsModule, NgClass, MediaPreviewComponent, AutoEvalutationStateComponent, EvaluationComponent]
})
export class ObservationComponent {

  @Input() scale: EvaluationScale;
  @Input() labelledObservation: LabelledObservation;
  @Input() op = false;
  @Input() edit = true;
  @Input() label = 'unset';
  @Input() displayAutoEvaluationIcon = false;
  @Output() changed  = new EventEmitter<Observation>();

  constructor(private readonly modalService: NgbModal) { }

  getObservationCode(): string {
    if (this.labelledObservation) {
      return this.labelledObservation.state;
    }
    return null;
  }

  levelChanged(code: string) {
    this.labelledObservation.state = code;
    this.labelledObservation.checked = false;
    this.changed.emit(this.labelledObservation);
  }
  inputUpdated() {
    this.changed.emit(this.labelledObservation);
  }
  changeMark() {
    const modalRef = this.modalService.open(MediaUploadModalComponent, {backdrop: 'static' , size: 'lg'});
    modalRef.componentInstance.mediaUrl = this.labelledObservation.markUrl;
    modalRef.componentInstance.onlyImages = false;
    modalRef.result.then(result => {
        this.labelledObservation.markUrl = result.url;
        this.labelledObservation.checked = false;
        this.changed.emit(this.labelledObservation);
      },
      () => { }
    );
  }
}
