import { Component, Input, Output, EventEmitter } from '@angular/core';
import { EvaluationScale, EvaluationLevel } from '@app/model';
import { SuppressModalComponent } from '@app/shared/components/suppress-modal/suppress-modal.component';
import { NgbModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { CommentModalComponent } from '@app/shared/components/comment-modal/comment-modal.component';
import { MediaUploadModalComponent } from '@app/shared/components/media-upload-modal/media-upload-modal.component';
import { NgFor, NgClass, NgStyle } from '@angular/common';
import { ActionMenuComponent } from '../../../shared/components/action-menu/action-menu.component';

@Component({
    selector: 'nemo-booklet-observation-actions-toolbar',
    templateUrl: './booklet-observation-actions-toolbar.component.html',
    styleUrls: ['./booklet-observation-actions-toolbar.component.css'],
    standalone: true,
    imports: [ActionMenuComponent, NgFor, NgbTooltip, NgClass, NgStyle]
})
export class BookletObservationActionsToolbarComponent {
  @Input() scale: EvaluationScale;
  @Output() update = new EventEmitter<EvaluationLevel>();
  @Output() comment = new EventEmitter<string>();
  @Output() markAll = new EventEmitter<string>();
  @Output() eraseAllComments = new EventEmitter();
  @Output() eraseAllEvaluations = new EventEmitter();
  @Output() eraseAllMarks = new EventEmitter();

  constructor(private readonly modalService: NgbModal) { }

  updateEvaluation(ev: EvaluationLevel) {
    this.update.emit(ev);
  }

  eraseAllPrompt() {
    const modalRef = this.modalService.open(SuppressModalComponent, { size: 'lg' });
    modalRef.componentInstance.title = 'Suppression des commentaires';
    modalRef.componentInstance.message = 'Voulez-vous supprimer tous les commentaires ?' ;
    modalRef.result.then(() => {
      this.eraseAllComments.emit();
    }, () => {
    });
  }
  eraseAllEvaluationsPrompt() {
    const modalRef = this.modalService.open(SuppressModalComponent, { size: 'lg' });
    modalRef.componentInstance.title = 'Suppression des évaluation';
    modalRef.componentInstance.message = 'Voulez-vous supprimer toutes les évalutations ?' ;
    modalRef.result.then((result) => {
      this.eraseAllEvaluations.emit();
    }, () => {
    });
  }
  eraseAllMarksPrompt() {
    const modalRef = this.modalService.open(SuppressModalComponent, { size: 'lg' });
    modalRef.componentInstance.title = 'Suppression des traces';
    modalRef.componentInstance.message = 'Voulez-vous supprimer toutes les traces ?' ;
    modalRef.result.then(() => {
      this.eraseAllMarks.emit();
    }, () => {
    });
  }
  commentAllPrompt() {
    const modalRef = this.modalService.open(CommentModalComponent, { size: 'lg' });
    modalRef.result.then((result) => {
      this.comment.emit(result);
    }, () => {
    });
  }
  markAllPrompt() {
    const modalRef = this.modalService.open(MediaUploadModalComponent, {backdrop: 'static' , size: 'lg'});
    modalRef.componentInstance.mediaUrl = '';
    modalRef.componentInstance.onlyImages = false;
    modalRef.result.then((result) => {
        this.markAll.emit(result.url);
      },
      () => { }
    );
  }
}
