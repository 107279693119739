import { Component, OnInit } from '@angular/core';
import { PreferencesService } from '@app/core/service';
import { UserNotificationService } from '@app/core/service/user-notification.service';
import { UserNotification, UserNotificationType } from '@app/model';
import { NgEventBus } from 'ng-event-bus';
import { Subscription } from 'rxjs';
import { MomentModule } from 'ngx-moment';
import { RouterLink } from '@angular/router';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { NgIf, NgFor } from '@angular/common';
import { BasepageComponent } from '../../../shared/components/basepage/basepage.component';

@Component({
    selector: 'nemo-notifications-liste',
    templateUrl: './notifications-liste.component.html',
    styleUrls: ['./notifications-liste.component.css'],
    standalone: true,
    imports: [BasepageComponent, NgIf, NgFor, NgbAlert, RouterLink, MomentModule]
})
export class NotificationsListeComponent implements OnInit {
  notifications: any[] = [];
  private subscription: Subscription;
  constructor(
    private readonly messageBusService: NgEventBus,
    private readonly userNotificationService: UserNotificationService,
    private readonly preferencesService: PreferencesService
  ) { }

  ngOnInit(): void {
    this.fetchNotifications();
    this.subscription = this.messageBusService.on('notif').subscribe(() => {
      this.fetchNotifications();
    });
  }
  fetchNotifications():void{
    this.userNotificationService.get().subscribe(r => {
      this.notifications = r;
    });
  }
  
  dismiss(n: UserNotification): void {
    this.userNotificationService.dismiss(n.id).subscribe(r => {
      if(r){
        this.notifications.splice(this.notifications.indexOf(n),1);
        this.messageBusService.cast('unotif');
      }
    });
  }
  dismissAll(): void {
    this.userNotificationService.dismissAll().subscribe(r => {
      if(r){
        this.notifications = [];
        this.messageBusService.cast('unotif-all');
      }
    });
  }
  calculateClasses(type: UserNotificationType): string[]{
    return [`alert`,`alert-${(type+'').toLowerCase()}`];
  }
  isAdmin():boolean {
    return this.preferencesService.isAdmin();
  }

}
