import { Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { AutoEvaluationService, BookletService, ToastService } from '@app/core/service';
import { AutoEvaluationBooklet, SelfEvaluationCriteria, EvaluationScale } from '@app/model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommentModalComponent } from '@app/shared/components/comment-modal/comment-modal.component';
import { EvaluationComponent } from '../../../shared/components/evaluation/evaluation.component';
import { NgIf, NgFor } from '@angular/common';
import { BasepageComponent } from '../../../shared/components/basepage/basepage.component';

@Component({
    selector: 'nemo-auto-evaluation-detail',
    templateUrl: './auto-evaluation-detail.component.html',
    styleUrls: ['./auto-evaluation-detail.component.css'],
    standalone: true,
    imports: [BasepageComponent, NgIf, RouterLink, NgFor, EvaluationComponent]
})
export class AutoEvaluationDetailComponent implements OnInit {

  evaluationBooklets: AutoEvaluationBooklet[];
  constructor(public router: Router,
    private readonly autoEvaluationService: AutoEvaluationService,
    private readonly bookletService: BookletService,
    private readonly toastService: ToastService,
    private readonly modalService: NgbModal) {
    }

  ngOnInit() {
    this.fetchData();
  }

  private fetchData() {
    this.autoEvaluationService.getEvaluation().subscribe(booklets => {
      this.evaluationBooklets = booklets;
      this.evaluationBooklets.forEach(b => {
        b.scale = this.bookletService.getEvaluationScale(b.professionalOption);
        b.autoEvaluationScale = this.bookletService.getSelfEvaluationScale(b.professionalOption);
      });
    });
  }


  selfEvaluationChanged(level: string, criteria: SelfEvaluationCriteria, scale: EvaluationScale) {
    if (scale.levels.filter(l => l.code === level)) {
      this.autoEvaluationService.evaluateCriteria(criteria.criteriaId, level).subscribe(res => {
        if (res) {
          criteria.selfObservationState = level;
          if (criteria.observationState && criteria.selfObservationState) {
            if (criteria.selfObservationState !== criteria.observationState) {
              this.toastService
              .warn('Ton avis est différent de celui de ton enseignant·e. Tu peux lui écrire un commentaire.');
            } else {
              this.toastService.success('Ton avis est le même que celui de ton enseignant·e.');
            }
          }
        }
      });
    } else {
      this.autoEvaluationService.evaluateCriteria(criteria.criteriaId, '').subscribe(res => {
        if (res) {
          criteria.selfObservationState = '';
        }
      });
    }
  }
  comment(criteria: SelfEvaluationCriteria) {
    const modalRef = this.modalService.open(CommentModalComponent, { size: 'lg' });
    modalRef.result.then((result) => {
      this.autoEvaluationService.commentCriteria(criteria.criteriaId, result).subscribe(res => {
        if (res) {
          criteria.selfObservationComment = result;
          this.toastService.info('Votre commentaire a été soumis à l\'équipe pédagogique.');
        }
      });
    }, () => {
    });
  }
}
