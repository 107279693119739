import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FocusDirective } from '@app/shared/directives/focus.directive';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'nemo-comment-modal',
    templateUrl: './comment-modal.component.html',
    styleUrls: ['./comment-modal.component.css'],
    standalone: true,
    imports: [FormsModule]
})
export class CommentModalComponent {

  comment = '';

  constructor(public activeModal: NgbActiveModal) { }

}
