import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReferenceDataService } from '@app/core/service';
import { Category } from '@app/model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CategorySelectorComponent } from '../category-selector/category-selector.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'nemo-category',
    templateUrl: './category.component.html',
    styleUrls: ['./category.component.css'],
    standalone: true,
    imports: [NgIf, NgFor]
})
export class CategoryComponent {

  @Input() selectedCategoryKeys: string[];
  
  get categories():Category[]{
    return this.referenceDataService.getCategories().filter(c=>this.selectedCategoryKeys.includes(c.key));
  }

  @Output() changed = new EventEmitter<string[]>();
  constructor(private readonly modalService: NgbModal,
  private readonly referenceDataService: ReferenceDataService) { }


  editCategories() {
    const modalRef = this.modalService.open(CategorySelectorComponent, { size: 'lg' });
    modalRef.componentInstance.selectedCategoriesKeys = this.selectedCategoryKeys;
    modalRef.result.then((cats) => {
      this.selectedCategoryKeys = cats;
      this.changed.emit(this.selectedCategoryKeys);
    }, () => {
    });
  }
  drop(c: Category){
    this.selectedCategoryKeys.splice(this.selectedCategoryKeys.indexOf(c.key), 1);
    this.changed.emit(this.selectedCategoryKeys);
  }
}
