import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PreferencesService, ReferenceDataService } from '@app/core/service';
import { Category } from '@app/model';
import { RouterLink } from '@angular/router';
import { LogoComponent } from '../logo/logo.component';
import { RibbonComponent } from '../ribbon/ribbon.component';
import { NgClass, NgIf, NgStyle, NgFor } from '@angular/common';

@Component({
    selector: 'nemo-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.css'],
    standalone: true,
    imports: [NgClass, NgIf, RibbonComponent, LogoComponent, NgStyle, RouterLink, NgFor]
})
export class CardComponent implements OnInit {


  public categories: Category[];
  public displayCategories: Category[]=[];

  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() ribbon?: string;
  @Input() tags?: string[] = [];
  // Liste des codes du Plan d'étude romand
  @Input() perCodes?: string[] = [];
  @Input() icon = 'edicons-visualidentity-rpn';
  @Input() color = 'c-transparent';
  @Input() progression?: number;
  @Input() author?: number;
  @Input() description?: string;
  @Input() text?: string;
  @Input() big = false;
  @Output() clicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() categoryclicked: EventEmitter<Category> = new EventEmitter<Category>();

  get displayAuthor (){
    return this.preferencesService.isPublisher();
  }

  public clickable = false;
  public isMoyenEnseignementRomand = false;
  public isMoyenEnseignementNeuchatelois = false;


  constructor(public referenceDataService: ReferenceDataService,
    private readonly preferencesService: PreferencesService) {
  }

  ngOnInit() {
    this.clickable = this.clicked.observed;
    if (this.color === null || this.color === '') {
      this.color = 'c-grey';
    }
    if (this.icon === null || this.icon === '') {
      this.icon = 'edicons-visualidentity-rpn';
    }
    

    const allCategories = this.referenceDataService.getCategories().filter(c=>this.tags.includes(c.key));
    
    this.categories = allCategories
      // Uniquement les tags (catégories) appartenants au parcours (selected) mais aussi :
      // - Toutes les disciplines et années du parcours
      .filter(c =>(['Année', 'Discipline'].indexOf(c.group) >= 0))
      .sort((l1, l2) => l1.ord - l2.ord);
    this.displayCategories = this.computeDisplayedCategories(this.categories);
    this.isMoyenEnseignementRomand=allCategories.filter(c => ['MER'].indexOf(c.key)>=0).length > 0;
    this.isMoyenEnseignementNeuchatelois= !this.isMoyenEnseignementRomand && allCategories.filter(c => ['MEN'].indexOf(c.key)>=0).length > 0;
  }

  public cardClick() {
    this.clicked.emit(this);
  }
  public catClicked(cat: Category) {
    this.categoryclicked.emit(cat);
  }
  isTeacher(): boolean {
    return this.preferencesService.isTeacher();
  }
  public computeDisplayedCategories (cats: Category[]): Category[]{
    return this.referenceDataService.computeCategories(cats);
  }

}
