import { Component, Input, OnInit } from '@angular/core';
import { MediaServerService } from '@app/core/service/media-server.service';
import { MediaAction } from '@app/model';
import { SafePipe } from '../../pipes/safe.pipe';
import { NgIf } from '@angular/common';

@Component({
    selector: 'nemo-media-display',
    templateUrl: './media-display.component.html',
    styleUrls: ['./media-display.component.css'],
    standalone: true,
    imports: [NgIf, SafePipe]
})
export class MediaDisplayComponent implements OnInit {

  @Input() mediaAction: MediaAction;
  otlLoaded = false;
  mediaUrlOtl = '';

  constructor(
    private readonly mediaServerService: MediaServerService
  ) { }

  ngOnInit(): void {
    if (this.mediaAction.video) {
      if (this.mediaAction.public) {
        this.mediaUrlOtl = this.mediaAction.url;
        this.otlLoaded = true;
      } else {
        this.mediaServerService.getMediaServerUrl(this.mediaAction.url).subscribe(r => {
          this.mediaUrlOtl = r;
          this.otlLoaded = true;
        });
      }

    }
  }

}
