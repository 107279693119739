import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CourseItem } from '@app/model';
import { ParcoursItemDetailComponent } from '../../../shared/components/parcours-item-detail/parcours-item-detail.component';

@Component({
    selector: 'nemo-parcours-item',
    templateUrl: './parcours-item.component.html',
    styleUrls: ['./parcours-item.component.css'],
    standalone: true,
    imports: [ParcoursItemDetailComponent]
})
export class ParcoursItemComponent {

  @Input() item: CourseItem;

  @Output() delete = new EventEmitter();
  @Output() moveup = new EventEmitter();
  @Output() movedown = new EventEmitter();
  @Output() edit = new EventEmitter();


  deleteStep(): void {
    this.delete.emit();
  }

  editStep(): void {
    this.edit.emit();
  }

  up(): void {
    this.moveup.emit();
  }

  down(): void {
    this.movedown.emit();
  }

}
