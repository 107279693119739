<header class="border-bottom d-print-none">
  <div class="container">
    <nemo-menu></nemo-menu>
  </div>
</header>
<section>
  <div class="container">
    <nemo-toast></nemo-toast>
    <router-outlet></router-outlet>
    <div class="my-2" *ngIf="testNotification()">
      <div class="input-group mb-3">
        <input type="text" class="form-control" placeholder="Username" aria-label="Username" [(ngModel)]="email" aria-describedby="basic-addon1">
        <button type="button" class="btn btn-outline-secondary" (click)="addNotification(email)"><em class="bi bi-trash-fill"></em> Add dummy</button>
      </div>
    </div>
  </div>
</section>
<footer class="footer border-top mt-5 d-print-none">
  <div class="container">
    <div class="row">
      <div class="col-md-12 text-end">
        <span *ngIf="settings.environment !== 'prod'" class="badge rounded-pill bg-info">{{settings.environment}}</span>
        <a class="me-2" [routerLink]="['/apropos/conditions/']">Conditions générales</a>
        <a class="me-2" [routerLink]="['/apropos/']">À propos</a>
        <a class="me-2" href="mailto: iclasse@rpn.ch?subject=Contact">Contact</a>
        <a class="me-2" href="http://blogs.rpn.ch/iclasse" target="_blank" rel="noopener noreferrer">Blog</a>
        <a class="me-2" href="http://blogs.rpn.ch/iclassecomm" target="_blank" rel="noopener noreferrer">Actualités</a>
        <a href="http://www.rpn.ch" target="_blank" rel="noopener noreferrer"><img src="/assets/rpn@2x.png" alt="logo réseau pédagogique neuchâtelois RPN"></a>
      </div>
    </div>
  </div>
</footer>
<a [ngClass]="{'show-scroll': showScroll}" (click)="scrollToTop()" class="scroll-to-top"  aria-label="Retour en haut de la page">
  <span class="bi bi-arrow-up"></span>
</a>
