import { Component, EventEmitter, Input, OnInit, Output, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { InputValidationService, ActivityService } from '@app/core/service';
import { ItemList, Progression } from '@app/model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Grid, GridCase, GridConfig } from './grid';
import { Subscription } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { ItemTextComponent } from '../common/item-text/item-text.component';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'nemo-grille',
    templateUrl: './grille.component.html',
    styleUrls: ['./grille.component.css'],
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        NgClass,
        ItemTextComponent,
        FormsModule,
        RouterLink,
    ],
})
export class GrilleComponent implements OnInit, OnDestroy {
  @Input() list: ItemList;
  @Output() activityProgress = new EventEmitter<Progression>(true);
  @Output() activityEnd = new EventEmitter(true);

  private progression: Progression;

  public config: GridConfig;
  public grid: Grid;

  first: GridCase;
  subscription: Subscription;
  @ViewChild('content', {
    static: true
  })
  private readonly configureBtnTemplate: TemplateRef<any>;
  constructor(
    private readonly router: Router,
    public inputValidationService: InputValidationService,
    public activityService: ActivityService,
    public modalService: NgbModal
  ) {
    this.subscription = activityService.configureActivity$.subscribe(
      conf => {
        this.configure();
    });
  }

  ngOnInit() {
    this.progression = {
      success: 0,
      danger: 0,
      info: 0,
      warning: 0,
    };
    this.activityProgress.emit(this.progression);
    this.config = this.activityService.getConfig<GridConfig>(
      'grille',
      {
        allowBackward: false,
        allowDiagonal: false,
        allowVertical: false,
        highlightFirstLetter: false
      }
    );
    const words = [].concat.apply(
      [],
      this.list.items
        .map(i =>
          this.inputValidationService.cleanText(i.learnTxt).toLocaleUpperCase()
        )
        .concat()
        .map(l => l.split(/\s+/))
    ).filter((v, i, a) => a.indexOf(v) === i).filter(a => a.length > 3).sort((a, b) => b.length - a.length);
    let finalList = words.slice(0, words[0].length + 3);
    finalList = this.activityService.shuffle(finalList);
    this.grid = new Grid(
      finalList ,
      this.config
    );
  }
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }

  configure() {
    this.modalService
      .open(this.configureBtnTemplate, { ariaLabelledBy: 'modal-basic-title', size: 'lg' })
      .result.then(
        result => {
          this.activityService.setConfig<GridConfig>(
            'grille',
            this.config
          );
          this.ngOnInit();
        },
        reason => {
          this.ngOnInit();
        }
      );
  }
  displayPreferences() {
    this.modalService.dismissAll();
    this.router.navigate(['/preferences/']);
  }
  public highlight(c: GridCase) {
    c.selected = !c.selected;
    if (this.first) {
      if (
        this.first.wordref.filter(value => -1 !== c.wordref.indexOf(value))
          .length === 1 &&
        this.first.startWord &&
        c.endWord
      ) {
        const wordId = this.first.wordref.filter(
          value => -1 !== c.wordref.indexOf(value)
        )[0];
        this.activityProgress.emit(this.progression);
        if (this.grid.validate(wordId)) {
          setTimeout(() => {
            this.activityEnd.emit();
          }, 800);
        }
        this.progression.success =
          (this.grid.words.filter(w => w.found).length /
            this.grid.words.length) *
          100;
      } else {
        this.first.selected = false;
        c.selected = false;
      }
      this.first = undefined;
    } else {
      this.first = c;
    }
  }
}
