import { Component } from '@angular/core';
import { BasepageComponent } from '../../../shared/components/basepage/basepage.component';

@Component({
    selector: 'nemo-conditions',
    templateUrl: './conditions.component.html',
    styleUrls: ['./conditions.component.css'],
    standalone: true,
    imports: [BasepageComponent]
})
export class ConditionsComponent {

  constructor() { }

}
