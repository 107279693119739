<div class="modal-header">
  <h4 class="modal-title">Critère</h4>
  <button type="button" class="btn-close" aria-label="btn-close" (click)="activeModal.dismiss()">

  </button>
</div>
<div class="modal-body">
  <div>
    <label class="form-label" for="criteriaLabel">Description</label>
    <div class="input-group input-group">
      <input ngbAutofocus id="criteriaLabel" type="text" class="form-control" [(ngModel)]="criteria.label" placeholder="critère" aria-label="critère"
        autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" maxlength="500" aria-describedby="criteriaHelp" />
    </div>
    <small id="criteriaHelp" class="text-muted d-block">500 caractères maximum.</small>
  </div>
  <div>
    <div class="d-flex flex-wrap py-1">
      <div class="flex-grow-1 align-self-center pe-2">
        <label class="lead form-check-label" for="studentEvaluationEnabled">Auto-évaluation par l'élève ?</label>
      </div>
      <div class="align-self-center">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" role="switch" id="studentEvaluationEnabled" [(ngModel)]="criteria.studentEvaluationEnabled" [ngModelOptions]="{standalone: true}">
        </div>
      </div>
    </div>
    <small id="autoEvalHelp" class="text-muted d-block">
      L'élève peut évaluer ses compétences et en cas de désaccord avec l'équipe pédagogique, il peut commenter son évaluation.
    </small>
  </div>
  <div>
    <label class="form-label" for="criteriaLabel">Discipline</label>
    <div class="input-group">
      <nemo-tags-toggler [tags]="[disciplines]" (click)="handleCategories()"></nemo-tags-toggler>
    </div>
  </div>
  <div>
    <label class="form-label" for="tags">Axes thématiques</label>
    <div class="input-group">
      <tag-input [(ngModel)]="criteria.axesThematiques" [modelAsStrings]="true" [placeholder]="'Ajouter'"
        [secondaryPlaceholder]="'Axes thématiques'" [maxItems]="2" aria-describedby="axeHelp"></tag-input>
    </div>
    <small id="axeHelp" class="text-muted d-block">
      Les axes thématique
    </small>
  </div>
  <div>
    <label class="form-label" for="tags">Catégories</label>
    <div class="input-group">
      <tag-input [(ngModel)]="criteria.tags" [modelAsStrings]="true" [placeholder]="'Ajouter'"
        [secondaryPlaceholder]="'Catégorie'" [maxItems]="1" aria-describedby="categoryHelp"></tag-input>

    </div>
    <small id="categoryHelp" class="text-muted d-block">
      La catégorie supplémentaire permet de regrouper les critères.
    </small>
  </div>
  <div class="flex-grow-1">
    <label class="form-label" for="tags">Plan d'étude romand</label>
    <div class="d-flex flex-column flex-xl-row align-self-center" >
      <div class="flex-grow-1 me-1" *ngIf="this.criteria.perId">
        <div class="row row-cols-1 row-cols-md-2 g-2">
          <div class="col">
            <div class="card">
              <div class="card-body">
                <div class="float-end">
                  <em class="bi bi-x-lg" (click)="dropPer()"></em>
                </div>
                <nemo-plan-etude-romand-display  [planEtudeRomandId]="this.criteria.perId"></nemo-plan-etude-romand-display>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2 mt-xl-0" *ngIf="!this.criteria.perId">
        <div class="d-grid gap-2">
          <button type="button" class="btn btn-sm btn-light mt-auto" (click)="addPlanEtudeRomand()"><span class="bi bi-plus-lg"></span> Plan d'étude romand</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="clone()">
    Cloner
  </button>
  <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss()">
    Annuler
  </button>
  <button type="button" class="btn btn-outline-primary" (click)="activeModal.close(criteria)">
    Valider
  </button>
</div>
