import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';
import { SettingsService } from '@app/core/service/settings.service';

@Component({
    selector: 'nemo-outils',
    templateUrl: './outils.component.html',
    styleUrls: ['./outils.component.css'],
    standalone: true,
    imports: [NgIf, RouterLink]
})
export class OutilsComponent {
  conjugaisonEnabled: boolean;

  constructor(private settings: SettingsService) {
    this.conjugaisonEnabled = settings.feature_flags.conjugaison;
  }



}
