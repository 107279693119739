import { Component, OnInit } from '@angular/core';
import { AutoEvaluationService, PreferencesService } from '@app/core/service';
import { RouterLink } from '@angular/router';
import { NgIf, NgClass } from '@angular/common';
import { BasepageComponent } from '../../../shared/components/basepage/basepage.component';
import { AuthenticationService } from '@app/core/service/authentication.service';


@Component({
    selector: 'nemo-accueil',
    templateUrl: './accueil.component.html',
    styleUrls: ['./accueil.component.css'],
    standalone: true,
    imports: [BasepageComponent, NgIf, RouterLink, NgClass]
})
export class AccueilComponent implements OnInit {

  selfEvaluationMenuEnabled = false;
  userProfile: any;

  constructor(
    private readonly authService: AuthenticationService,
    private readonly preferencesService: PreferencesService,
    private readonly autoEvaluationService: AutoEvaluationService
  ) {
    
  }
  ngOnInit(): void {
    this.autoEvaluationService.hasEvaluation().subscribe(r => this.selfEvaluationMenuEnabled = r);
  }

  get isLoggedIn(): boolean {
    return this.authService.getCurrentUserProfile().isAuthenticated;
  }

  public isTeacher(): boolean {
    return this.preferencesService.isTeacher();
  }
}
