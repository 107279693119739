import { Component, Input } from '@angular/core';
import { NgbModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { AutoEvaluationModalComponent } from '../auto-evaluation-modal/auto-evaluation-modal.component';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'nemo-auto-evalutation-state',
    templateUrl: './auto-evalutation-state.component.html',
    styleUrls: ['./auto-evalutation-state.component.css'],
    standalone: true,
    imports: [NgIf, NgbTooltip, NgClass]
})
export class AutoEvalutationStateComponent {

  @Input() autoEvaluationEnabled: boolean;
  @Input() op: boolean;
  @Input() state: string;
  @Input() selfState: string;
  @Input() selfComment: string;

  constructor(private readonly modalService: NgbModal) { }

  displayAutoEvaluation() {
    const modalRef = this.modalService.open(AutoEvaluationModalComponent, { size: 'lg' });
    modalRef.componentInstance.selfComment = this.selfComment ;
    modalRef.componentInstance.selfState = this.selfState;
    modalRef.componentInstance.op = this.op;

    modalRef.result.then((result) => {
    }, () => {
    });
  }

}
