import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from '@app/core/service';
import { ToastType, Toast } from '@app/model';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs';

@Component({
    selector: 'nemo-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.css'],
    standalone: true
})
export class ToastComponent implements OnInit {

   constructor(private readonly toastService: ToastService,
    private readonly router: Router,
    private readonly toastr: ToastrService) { }

  ngOnInit() {
      this.toastService.getAlert().subscribe((toast: Toast) => {
          if (toast != null) {
            switch (toast.type) {
              case ToastType.Success:
                return this.toastr.success(toast.message, null, toast.options).onTap
                .pipe(take(1))
                .subscribe(() => this.toasterClickedHandler(toast.url));
              case ToastType.Error:
                return this.toastr.error(toast.message, null, toast.options).onTap
                .pipe(take(1))
                .subscribe(() => this.toasterClickedHandler(toast.url));
              case ToastType.Info:
                return this.toastr.info(toast.message, null, toast.options).onTap
                .pipe(take(1))
                .subscribe(() => this.toasterClickedHandler(toast.url));
              case ToastType.Warning:
                return this.toastr.warning(toast.message, null, toast.options).onTap
                .pipe(take(1))
                .subscribe(() => this.toasterClickedHandler(toast.url));
              default:
                return null;
            }
          }
        return null;
      });
  }
  toasterClickedHandler(url: string) {
    if(url) {
      if(url.startsWith('http')){
        window.open(url, '_blank');
      }else if(url.startsWith('/')){
        this.router.navigateByUrl(url);
      }
    }
  }

}
