import { Component, OnInit, ElementRef, ViewChild, OnDestroy, HostListener } from '@angular/core';
import { Booklet, Logo, Criteria, MonitoredStudent, Group, Student, Teacher, GrantedTeacher, Category, PlanEtudeRomandSearchViewModel } from '@app/model';
import { FormGroup, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute, RouterLink } from '@angular/router';
import { BookletService, ToastService, PreferencesService,
  InputValidationService, StudentService, ReferenceDataService } from '@app/core/service';
import { DragulaService, DragulaModule } from 'ng2-dragula';
import { NgbModal, NgbCollapse, NgbNav, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavContent, NgbNavOutlet } from '@ng-bootstrap/ng-bootstrap';
import { SuppressModalComponent } from '@app/shared/components/suppress-modal/suppress-modal.component';
import { CriteriaEditModalComponent } from '../criteria-edit-modal/criteria-edit-modal.component';
import { ImportFromTextModalComponent } from '@app/shared/components/import-from-text-modal/import-from-text-modal.component';
import { CanComponentDeactivate } from '@app/core/guards/CanComponentDeactivate';
import { Observable } from 'rxjs';
import { DialogService } from '@app/core/service/dialog.service';
import { TransmitModalComponent } from '@app/shared/components/transmit-modal/transmit-modal.component';
import { TeacherSearchComponent } from '../../../shared/components/teacher-search/teacher-search.component';
import { UserDisplayComponent } from '../../../shared/components/user-display/user-display.component';
import { StudentSearchComponent } from '../../../shared/components/student-search/student-search.component';
import { CriteriaComponent } from '../criteria/criteria.component';
import { ActionMenuComponent } from '../../../shared/components/action-menu/action-menu.component';
import { LogoComponent } from '../../../shared/components/logo/logo.component';
import { NgIf, NgFor } from '@angular/common';
import { BasepageComponent } from '../../../shared/components/basepage/basepage.component';
import { SettingsService } from '@app/core/service/settings.service';
import { PlanEtudeRomandSelectorModalComponent } from '@app/shared/components/plan-etude-romand-selector-modal/plan-etude-romand-selector-modal.component';

@Component({
    selector: 'nemo-booklet-edit',
    templateUrl: './booklet-edit.component.html',
    styleUrls: ['./booklet-edit.component.css'],
    standalone: true,
    imports: [BasepageComponent, NgIf, FormsModule, ReactiveFormsModule, LogoComponent, NgbCollapse, RouterLink, NgbNav, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavContent, ActionMenuComponent, DragulaModule, NgFor, CriteriaComponent, StudentSearchComponent, UserDisplayComponent, TeacherSearchComponent, NgbNavOutlet]
})
export class BookletEditComponent implements OnInit, OnDestroy, CanComponentDeactivate {

  @ViewChild('bottomElem', { static: false }) BottomElement: ElementRef;
  init = false;

  @HostListener('window:keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if ((event.metaKey || event.ctrlKey) && event.key === 's') {
        this.submitForm();
        event.preventDefault();
    }
  }

  public booklet: Booklet;
  public userForm: FormGroup;
  public typelabel: string;
  public closeResult: string;
  public publisher = false;
  public isCollapsed = true;
  public groups: Group[];
  public categories: Category[];
  public featureTransmitEnabled = false;
  public saving = false;

  model: any;
  searching = false;
  searchFailed = false;

  constructor(public router: Router,
    private readonly service: BookletService,
    private readonly route: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private readonly dragulaService: DragulaService,
    private readonly toastService: ToastService,
    public preferencesService: PreferencesService,
    private readonly modalService: NgbModal,
    private readonly studentService: StudentService,
    public inputValidationService: InputValidationService,
    private readonly referenceDataService: ReferenceDataService,
    private readonly dialogService: DialogService,
    private readonly settings: SettingsService
  ) {
    this.dragulaService.drag('dnditems').subscribe(({el, source}) => {
      this.userForm.markAsDirty();
    });
  }

  ngOnInit() {
    this.route.queryParams
    .subscribe(params => {
      this.init = params.init;
    }
  );
    this.route.params.subscribe(params => {
      this.featureTransmitEnabled = this.settings.feature_flags.transmit_folio;
      this.categories = this.referenceDataService.getCategories();
      this.service.get(params['id']).subscribe(list => {
        this.booklet = list;
        if(!list.granted && !list.mine){
          this.router.navigate(['/']);
        }
        this.dragulaService.createGroup('dnditems', {
          moves: function (el, source, handle, sibling) {
            return handle.className.indexOf('bi-three-dots-vertical') >= 0;
          }
        });
        this.studentService.getGroups().subscribe(g => {
          this.groups = g;
          this.initForm();
        });
        this.initForm();
      });
    });
    this.publisher = this.preferencesService.isPublisher();
  }

  canDeactivate(): Observable<boolean> | boolean {
    // Allow synchronous navigation (`true`) if no crisis or the crisis is unchanged
    if (!this.userForm.dirty) {
      return true;
    }
    // Otherwise ask the user with the dialog service and return its
    // observable which resolves to true or false when the user decides
    return this.dialogService.confirm('Vous n\'avez pas sauvegardé les modifications, souhaitez-vous continuer sans sauvegarder ?');
  }

  ngOnDestroy() {
    if (this.dragulaService.find('dnditems') !== undefined) {
      this.dragulaService.destroy('dnditems');
    }
  }

  initForm() {
    this.userForm = this.formBuilder.group({
      title: [this.booklet.title, [Validators.required]]
    });
  }

  submitForm(): void {
    this.booklet.title = this.inputValidationService.cleanItem(this.userForm.get('title').value);
    this.saving = true;
    this.service.save(this.booklet).subscribe((list) => {
      this.booklet = list;
      this.initForm();
      this.toastService.success('Folio sauvegardé');
      this.saving = false;
      this.userForm.markAsPristine();
    });
  }
  updateLogo(logo: Logo) {
    this.booklet.color = logo.color;
    this.booklet.icon = logo.icon;
    this.userForm.markAsDirty();
  }

  deleteList() {
    const modalRef = this.modalService.open(SuppressModalComponent, { size: 'lg' });
    modalRef.result.then((result) => {
      this.service.delete(this.booklet.id).subscribe((res) => {
        this.router.navigate(['/folios/']);
      });
    }, () => {
    });
  }

  deleteCriteria(criteria: Criteria) {
    this.booklet.criterias.splice(this.booklet.criterias.indexOf(criteria), 1);
    this.userForm.markAsDirty();
  }

  addCriteria(pos = -1, criteria: Criteria = {label:'',id:-this.booklet.criterias.length}) {
    const modalRef = this.modalService.open(CriteriaEditModalComponent, {backdrop: 'static' ,  size: 'lg' });
    modalRef.componentInstance.criteria = criteria;
    modalRef.hidden.subscribe(o => {
      if(pos === -1) {
        document.getElementById("end").scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }
    });
    modalRef.result.then((result:Criteria) => {
      if(pos === -1) {
        this.booklet.criterias.push(result);
        this.mapCategories();
      } else {
        this.booklet.criterias.splice(pos, 0, result);
      }
      this.userForm.markAsDirty();
    }, () => {
    });

    this.userForm.markAsDirty();
  }
  addFromPer(){
    const modalRef = this.modalService.open(PlanEtudeRomandSelectorModalComponent, {backdrop: 'static' ,  size: 'xl' });
    modalRef.componentInstance.multiSelect=true;
    modalRef.componentInstance.hasCriteria=true;
    modalRef.result.then((result:PlanEtudeRomandSearchViewModel[]) => {
      this.service.getCriteriasForPlanEtudesRomand(result.map(i=>i.id)).subscribe(criterias=>{
        criterias.forEach(c=>{
          this.booklet.criterias.push(c);
        })
        this.mapCategories();
        this.userForm.markAsDirty();
      });
      
    }, () => {
    });
  }
  editCriteria(criteria: Criteria) {
    const modalRef = this.modalService.open(CriteriaEditModalComponent, {backdrop: 'static' ,  size: 'lg' });
    modalRef.componentInstance.criteria = Object.assign({}, criteria );
    const index = this.booklet.criterias.indexOf(criteria);
    modalRef.result.then((result) => {
      this.booklet.criterias[index] = new Criteria({
        id: result.id,
        label: result.label,
        studentEvaluationEnabled: result.studentEvaluationEnabled,
        categories: result.categories,
        perId: result.perId,
        perObjectiveCode: result.perObjectiveCode,
        axesThematiques: result.axesThematiques,
        tags: result.tags
      });
      this.mapCategories();
      this.userForm.markAsDirty();
    }, (reason) => {
      if(reason == 'clone'){
        const clonedCriteria={...criteria};
        clonedCriteria.id=-this.booklet.criterias.length;
        this.addCriteria(index + 1, clonedCriteria);
      }
      console.log(reason);
    });
    this.userForm.markAsDirty();
  }
  mapCategories(): void {
    this.booklet.categories = [...new Set(this.booklet.criterias.map(c=>c.categories).flat())];
  }
  itemUpdated() {
    this.userForm.markAsDirty();
  }

  dropCriteria(criteria: Criteria) {
    this.booklet.criterias.splice(this.booklet.criterias.indexOf(criteria), 1);
    this.userForm.markAsDirty();
  }

  private move(array, element, delta) {
    const index = array.indexOf(element);
    const newIndex = index + delta;
    if (newIndex < 0 || newIndex === array.length) {
      return;
    } // Already at the top or bottom.
    const indexes = [index, newIndex].sort(); // Sort the indexes
    array.splice(indexes[0], 2, array[indexes[1]], array[indexes[0]]); // Replace from lowest index, two elements, reverting the order
  }


  dropStudents() {
    this.booklet.monitoredStudents = new Array<MonitoredStudent>();
    this.userForm.markAsDirty();
  }

  dropStudent(student: MonitoredStudent) {
    this.booklet.monitoredStudents.splice(this.booklet.monitoredStudents.indexOf(student), 1);
    this.userForm.markAsDirty();
  }


  addStudents(group: Group) {
    group.students.forEach(student => {
      this.addStudent(student);
    });
  }
  addStudent(student: Student) {
    if (this.booklet.monitoredStudents.filter(s => s.cloeeWorldId === student.cloeeWorldId).length === 0) {
      this.booklet.monitoredStudents.push(new MonitoredStudent({
        cloeeWorldId: student.cloeeWorldId,
        displayName: student.displayName,
        id: -this.booklet.monitoredStudents.length
      }));
      this.userForm.markAsDirty();
    }
  }
  
  dropTeachers() {
    this.booklet.grantedTeachers = new Array<GrantedTeacher>();
    this.userForm.markAsDirty();
  }

  dropTeacher(teacher: Teacher) {
    this.booklet.grantedTeachers.splice(this.booklet.grantedTeachers.indexOf(teacher), 1);
    this.userForm.markAsDirty();
  }

  addTeachers(teachers: Teacher[]) {
    teachers.forEach(teacher => {
      this.addTeacher(teacher);
    });
  }
  addTeacher(teacher: Teacher) {
    if (this.booklet.grantedTeachers.filter(s => s.sid === teacher.sid).length === 0) {
      this.booklet.grantedTeachers.push(new Teacher({
        displayName: teacher.displayName,
        samAccountName: teacher.samAccountName,
        department: teacher.department,
        description: teacher.description,
        sid: teacher.sid,
        email: teacher.email,
        employeeId: teacher.employeeId,
        objectGuid: teacher.objectGuid,
        upn: teacher.upn
      }));
      this.sortTeachers();
      this.userForm.markAsDirty();
    }
  }
  sortTeachers() {
    this.booklet.grantedTeachers.sort((a, b) => a.displayName.localeCompare(b.displayName));
  }
  importFromText() {
    const modalRef = this.modalService.open(ImportFromTextModalComponent, {
      size: 'lg'
    });
    modalRef.result.then(
      result => {
        result.txt.split('\n').forEach(line => {
          if (line.split('\t').length <= 11) {
            const elems = line.split('\t');
            this.booklet.criterias.push(
              new Criteria({
                id: -1,
                label: elems.length > 0 ? elems[0].substring(0, 149) : '',
                categories: elems.length > 1 ? elems.slice(1, 2) : [],
                tags: elems.length > 2 ? elems.slice(2) : []
              })
            );
          }
        });
        this.userForm.markAsDirty();
      },
      () => { }
    );
  }
  sortCriterias() {
    this.booklet.criterias.sort((a, b) => {
      // sort by discipline disciplines are categories here... :-/
      if (a.categories.length > 0 && b.categories.length > 0) {

        const catA = this.categories.find(f => f.key === a.categories[0]);
        const catB = this.categories.find(f => f.key === b.categories[0]);
        if  (catA.ord !== catB.ord) {
          return catA.ord - catB.ord;
        }
      }
      // if there is a discipline it comes first
      if (a.categories.length > 0 && b.categories.length === 0) {
        return  -1;
      }
      if (b.categories.length > 0 && a.categories.length === 0) {
        return 1;
      }


      // then by category
      if (a.tags.length > 0 && b.tags.length > 0 && a.tags[0] !== b.tags[0]) {
        return a.tags[0].localeCompare(b.tags[0]);
      }
      // if there is a category it comes first
      if (a.tags.length > 0 &&  b.tags.length === 0) {
        return -1;
      }
      if (b.tags.length > 0 &&  a.tags.length === 0) {
        return 1;
      }

      // finally by label --> General Comment at end mandatory
      if (a.label === 'Commentaire général') {
        return 1;
      }
      if (b.label === 'Commentaire général') {
        return -1;
      }
      return a.label.localeCompare(b.label);
    });
    this.userForm.markAsDirty();
  }
  activateAutoEvaluation() {
    this.booklet.criterias.forEach(c => c.studentEvaluationEnabled = true);
    this.userForm.markAsDirty();
  }
  deactivateAutoEvaluation() {
    this.booklet.criterias.forEach(c => c.studentEvaluationEnabled = false);
    this.userForm.markAsDirty();
  }
  openTransitModal() {
    const modalRef = this.modalService.open(TransmitModalComponent, { size: 'lg' });
    modalRef.result.then((result: Teacher) => {
      this.service.transmit(result, this.booklet.id).subscribe((res) => {
        if (res) {
          this.toastService.success(`Le folio a été transmis à ${result.displayName}`);
          this.router.navigate(['/folios/']);
        } else {
          this.toastService.warn('Malheureusement le folio n\' a pas pu être transmis.');
        }
      });
    }, () => {
    });
  }

}
