import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { ActivityService } from '@app/core/service';
import { Item, ItemList, Progression } from '@app/model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DragulaService, DragulaModule } from 'ng2-dragula';
import { Subscription } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { ItemTextComponent } from '../common/item-text/item-text.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'nemo-tri',
    templateUrl: './tri.component.html',
    styleUrls: ['./tri.component.css'],
    animations: [
        trigger('itemState', [
            state('found', style({
                opacity: 0,
            })),
            state('notfound', style({
                opacity: 1,
            })),
            state('fail', style({
                opacity: 1,
            })),
            transition('notfound => found', [
                animate(300, keyframes([
                    style({ transform: 'translateY(0)', offset: 0 }),
                    style({ transform: 'translateY(15px)', offset: 0.7 }),
                    style({ transform: 'translateY(-100%)', offset: 1.0 }),
                ])),
            ]),
            transition('found => notfound', [
                animate(300, keyframes([
                    style({ transform: 'translateY(100%)', offset: 0 }),
                    style({ transform: 'translateY(15px)', offset: 0.3 }),
                    style({ transform: 'translateY(0)', offset: 1.0 }),
                ])),
            ]),
            transition('notfound => fail', [
                animate(300, keyframes([
                    style({ transform: 'translateX(0)', offset: 0 }),
                    style({ transform: 'translateX(-15px)', offset: 0.33 }),
                    style({ transform: 'translateX(15px)', offset: 0.66 }),
                    style({ transform: 'translateX(0)', offset: 1.0 }),
                ])),
            ]),
        ]),
    ],
    standalone: true,
    imports: [
        NgIf,
        DragulaModule,
        NgFor,
        ItemTextComponent,
        FormsModule,
        RouterLink,
    ],
})
export class TriComponent implements OnInit, OnDestroy {
  @Input() list: ItemList;
  @Output() activityProgress = new EventEmitter<Progression>(true);
  @Output() activityEnd = new EventEmitter(true);

  private progression: Progression;
  private tries = 0;
  public state = 'notfound';
  public config: TriConfig;
  public allItems: Item[];
  public workinglist: Item[];
  private countPanels = 1;
  subscription: Subscription;

  @ViewChild('content', {
    static: true
  })
  private readonly configureBtnTemplate: TemplateRef<any>;

  constructor(
    private readonly router: Router,
    private readonly activityService: ActivityService,
    private readonly dragulaService: DragulaService,
    public modalService: NgbModal
  ) {
    this.subscription = activityService.configureActivity$.subscribe(
      conf => {
        this.configure();
    });
  }

  ngOnInit() {
    this.progression = {
      success: 100,
      danger: 0,
      info: 0,
      warning: 0,
    };
    this.countPanels = 1;
    this.activityProgress.emit(this.progression);
    this.config = this.activityService.getConfig<TriConfig>('tri', { howmanywords: 2, hide: 0 });
    this.allItems = Object.assign([], this.list.items);
    this.allItems = this.activityService.shuffle(this.allItems);
    this.workinglist = [];
    this.alimWorkingItems();
  }

  configure() {
    this.modalService
      .open(this.configureBtnTemplate, { ariaLabelledBy: 'modal-basic-title', size: 'lg' })
      .result.then(
        result => {
          this.activityService.setConfig('tri', this.config);
          this.ngOnInit();
        },
        reason => {
          this.ngOnInit();
        }
      );
  }

  displayPreferences() {
    this.modalService.dismissAll();
    this.router.navigate(['/preferences/']);
  }

  validate() {
    const test = this.workinglist
      .map(i => i.learnTxt)
      .sort((l1, l2) => l1.localeCompare(l2)).toString() ===
      this.workinglist.map(i => i.learnTxt).toString();
    if (test) {
      this.state = 'found';
      if (this.allItems.length === 0) {
        setTimeout(() => {
          this.activityEnd.emit();
        }, 800);
      } else {
        setTimeout(() => {
          this.alimWorkingItems();
        }, 800);
      }
    } else {
      this.state = 'fail';
      this.tries++;
      this.progression.success = 100 - this.tries * 20;
      this.progression.warning = 100 - this.progression.success;
      this.activityProgress.emit(this.progression);
    }
    setTimeout(() => {
      this.state = 'notfound';
    }, 500);
  }
  restart() {
    this.ngOnInit();
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
    if (this.dragulaService.find('dnditems') !== undefined) {
      this.dragulaService.destroy('dnditems');
    }
  }
  alimWorkingItems() {
    switch (this.config.howmanywords) {
      case 0:
        this.workinglist = this.allItems.splice(0, this.list.items.length);
        break;
      case 1:
        this.workinglist = this.allItems.splice(0,
          this.activityService.optimizedArrayChunkSize(this.list.items.length, 5, this.countPanels));
        break;
      case 2:
        this.workinglist = this.allItems.splice(0,
          this.activityService.optimizedArrayChunkSize(this.list.items.length, 10, this.countPanels));
        break;
      default:
    }
    this.countPanels++;
  }
}
export class TriConfig {
  howmanywords: number; // 0= all, 1= 5, 2 = 10
  hide: number; // 0= hide nothing, 4 = hide left side, 2= hide all
}
