import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ParcoursService, PlanEtudeRomandService, PreferencesService, ReferenceDataService } from '@app/core/service';
import { Category, Course, CourseSummary, Search, SearchNatureEnum } from '@app/model';
import { PlanEtudeRomandSearchViewModel } from '@app/model/plan-etude-romand/plan-etude-romand-search-view-model';
import { ExportComponent } from '@app/shared/components/export/export.component';
import { NewCategorySelectorComponent } from '@app/shared/components/new-category-selector/new-category-selector.component';
import { PlanEtudeRomandSelectorModalComponent } from '@app/shared/components/plan-etude-romand-selector-modal/plan-etude-romand-selector-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CardComponent } from '../../../shared/components/card/card.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FormsModule } from '@angular/forms';
import { NewCategorySelectorComponent as NewCategorySelectorComponent_1 } from '../../../shared/components/new-category-selector/new-category-selector.component';
import { PlanEtudeRomandDisplayComponent } from '../../../shared/components/plan-etude-romand-display/plan-etude-romand-display.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'nemo-parcours-recherche',
    templateUrl: './parcours-recherche.component.html',
    styleUrls: ['./parcours-recherche.component.css'],
    standalone: true,
    imports: [NgIf, RouterLink, PlanEtudeRomandDisplayComponent, NewCategorySelectorComponent_1, FormsModule, InfiniteScrollModule, CardComponent, NgFor]
})
export class ParcoursRechercheComponent implements OnInit {

  @Input() libre = false;
  public private = false;
  public rpn = false;
  public teacher = false;

  public lists: CourseSummary[];
  public filter: Search;
  public selectedPlanEtudeRomandSearchViewModel: PlanEtudeRomandSearchViewModel = undefined;
  public loading = true;
  public endReached = false;
  public loadMore = false;
  public searching = false;
  selectableCategories: string[];

  public today = new Date();
  @ViewChild('cat') cat:NewCategorySelectorComponent;
  parentCategorySelected = false;

  constructor(
    public router: Router,
    private readonly parcoursservice: ParcoursService,
    private readonly route: ActivatedRoute,
    private readonly preferencesService: PreferencesService,
    private readonly modalService: NgbModal,
    private readonly planEtudeRomandService: PlanEtudeRomandService,
    private readonly referenceDataService: ReferenceDataService
  ) {
  }

  ngOnInit() {
    this.filter = this.parcoursservice.getSearchFilter();
    
    switch (this.filter.nature) {
      case SearchNatureEnum.Rpn :
        this.rpn = true;
        break;
      case SearchNatureEnum.Public:
        this.libre = true;
        this.parentCategorySelected = this.filter.categories.length ===1 && this.filter.categories.filter(c=>c==='PAR').length === 1 && this.libre;
        break;
      case SearchNatureEnum.Private:
        this.private = true;
        break;
      case SearchNatureEnum.ForTeacher:
        this.teacher = true;
        break;
    }
    if (this.filter.planEtudeRomandId && this.filter.planEtudeRomandId!='') {
      this.planEtudeRomandService.get(this.filter.planEtudeRomandId).subscribe(p=>{
        this.selectedPlanEtudeRomandSearchViewModel = p;
      })
    }
    this.route.data.subscribe((data: { courses: CourseSummary[] }) => {
      this.lists = data.courses;
      this.loading = false;
    });
    this.referenceDataService.getCategoriesForRessources('parcours',this.filter.nature).subscribe(cats=>{
      this.selectableCategories = cats;
    })
  }

  public searchPer():void {
    const modalRef = this.modalService.open(PlanEtudeRomandSelectorModalComponent, {
      scrollable: true,
      size: 'xl'
    });
    modalRef.result.then(
      result => {
        this.selectedPlanEtudeRomandSearchViewModel = result;
        this.fetchCourses(false);
      }, ()=>{ 
}
    );
  }
  public clearPer():void {
    this.selectedPlanEtudeRomandSearchViewModel = undefined;
    this.fetchCourses(false);
  }

  public newCourse() {
    this.parcoursservice.getNew().subscribe((t: Course) => {
      this.router.navigate(['/parcours/personnels', t.id, 'edition']);
    });
  }

  public search() {
    this.searching = true;
    this.fetchCourses(false);
  }

  itemUpdated(onlyDistributer: boolean) {
    this.filter.onlyDistributed = onlyDistributer;
    this.fetchCourses(false);
  }
  
  public filterCategory(s: any) {
    this.filter.categories= s;
    this.searching = true;
    this.parentCategorySelected = this.filter.categories.length ===1 && this.filter.categories.filter(c=>c==='PAR').length === 1 && this.libre;
    document.querySelector('header').scrollIntoView({ behavior: 'smooth' });
    this.fetchCourses(false);
  }

  public fetchCourses(concat: boolean) {
    if (!concat) {
      this.filter.skip = 0;
    }
    if (this.selectedPlanEtudeRomandSearchViewModel != undefined)
    {
      this.filter.planEtudeRomandId = this.selectedPlanEtudeRomandSearchViewModel.id;
    }
    else{
      this.filter.planEtudeRomandId = '';
    }
    
    this.parcoursservice.setSearchFilter(this.filter);
    this.parcoursservice.getCourses(this.filter).subscribe(courses => {
      this.lists = concat ? this.lists.concat(courses) : courses;
      this.searching = false;
      this.loadMore = false;
      if (courses.length === 0) {
        this.endReached = true;
      } else {
        this.endReached = false;
      }
    });
  }
  clearTerm():void {
    this.filter.title = '';
    this.fetchCourses(false);
  }
  public clearFilter() {
    this.filter.title = '';
    this.filter.categories = [];
    this.filter.onlyDistributed = false;
    this.filter.planEtudeRomandId = '';
    this.selectedPlanEtudeRomandSearchViewModel = undefined;
    this.searching = true;
    this.cat.reinit();
    this.parentCategorySelected = this.filter.categories.length ===1 && this.filter.categories.filter(c=>c==='PAR').length === 1 && this.libre;
    this.fetchCourses(false);
  }
  public isFiltered(): boolean {
    return !((this.filter.title === null || this.filter.title === '') && this.filter.categories.length === 0 && this.filter.planEtudeRomandId.length === 0);
  }

  exportList() {
    if (window && window.location) {
      const modalRef = this.modalService.open(ExportComponent, { size: 'lg' });
      modalRef.componentInstance.title = 'Partager les parcours';
      modalRef.componentInstance.url = window.location.origin +
      this.router.createUrlTree([
        '/',
        'filtre',
        'parcours',
        this.filter.nature.toString(),
        this.filter.title.length === 0 ? '_' : this.filter.title,
        this.filter.categories.length === 0 ? '_' : this.filter.categories.join('-')
      ]);
    }
  }

  isPublisher(): boolean {
    return this.preferencesService.isPublisher();
  }

  isTeacher(): boolean {
    return this.preferencesService.isTeacher();
  }

  loadMoreItems() {
    if (!this.endReached) {
      this.loadMore = true;
      this.filter.skip = this.lists.length;
      this.fetchCourses(true);
    }
  }

  selectPlanEtudeRomand(per: PlanEtudeRomandSearchViewModel):void {
    this.selectedPlanEtudeRomandSearchViewModel = per;
    this.fetchCourses(false);
  }

  courseClicked() {

  }
  fetchParentsParcours(){
    //this.cat.reinit();
    
    this.referenceDataService.getCategoriesForRessources('parcours',this.filter.nature).subscribe(cats=>{
      this.parentCategorySelected = true;
      this.cat.reinit();
      this.filter.categories = ['PAR'];
      this.searching = true;
      
      this.cat.toggle(this.referenceDataService.categories.filter(c=>c.key=='PAR')[0]);
      document.querySelector('header').scrollIntoView({ behavior: 'smooth' });
      this.fetchCourses(false);
    })
  }
}
