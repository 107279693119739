<div class="sticked-top d-print-none">
  <div class="card my-2">
    <div class="card-body">
      <button type="button" class="btn btn-outline-secondary" tooltip="Commentaire unique"
        tooltipPlacement="top" (click)="commentAllPrompt()"><span class="bi bi-chat-fill"></span> Commentaire unique</button>
      <nemo-action-menu [placement]="'bottom-left'">
        <button class="dropdown-item" (click)="eraseAllMarksPrompt()">Effacer les traces</button>
        <button class="dropdown-item" (click)="eraseAllPrompt()">Effacer les commentaires</button>
        <button class="dropdown-item" (click)="eraseAllEvaluationsPrompt()">Effacer les évaluations</button>
      </nemo-action-menu>

      <div class="btn-group float-end" role="group" aria-label="Evaluation">
        <button *ngFor="let level of scale.levels" class="btn btn-light" type="button" ngbTooltip="{{ level.label }} pour tous"  (click)="updateEvaluation(level)" name="options" id="option1" autocomplete="off" checked>
          <span [ngClass]="[level.icon]" [ngStyle]="{'color': level.color}"></span>
        </button>
      </div>
    </div>
  </div>
</div>
