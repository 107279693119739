import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Criteria, FilterCategory, PlanEtudeRomandSearchViewModel } from '@app/model';
import { ReferenceDataService } from '@app/core/service';
import { FocusDirective } from '@app/shared/directives/focus.directive';
import { TagInputModule } from 'ngx-chips';
import { TagsTogglerComponent } from '../../../shared/components/tags-toggler/tags-toggler.component';
import { FormsModule } from '@angular/forms';
import { PlanEtudeRomandSelectorModalComponent } from '@app/shared/components/plan-etude-romand-selector-modal/plan-etude-romand-selector-modal.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';

@Component({
    selector: 'nemo-criteria-edit-modal',
    templateUrl: './criteria-edit-modal.component.html',
    styleUrls: ['./criteria-edit-modal.component.css'],
    standalone: true,
    imports: [CommonModule,SharedModule,FormsModule, TagsTogglerComponent, TagInputModule]
})
export class CriteriaEditModalComponent implements OnInit {

  @Input() criteria: Criteria;
  @ViewChild(FocusDirective, { static: true }) input: FocusDirective;

  public disciplines: FilterCategory[];

  constructor(public activeModal: NgbActiveModal,
    private readonly modalService: NgbModal,
    public referenceDataService: ReferenceDataService) {}

  ngOnInit() {
    this.disciplines = this.referenceDataService.getCategories().filter(c=>c.group === 'Discipline' || c.group === 'Année')
    //.sort((a, b) => a.ord - b.ord)
    .map(d=>{
      return {
        category:d,
        group:d.group,
        selected:this.criteria.categories?.includes(d.key),
        value:d.value
      }});
  }
  handleCategories() {
    this.criteria.categories = this.disciplines.filter(d=>d.selected).map(d => d.category.key);
  }
  dropPer() {
    this.criteria.perId = undefined;
    this.criteria.perObjectiveCode = undefined;
  }
  addPlanEtudeRomand():void{
    const modalRef = this.modalService.open(PlanEtudeRomandSelectorModalComponent,{size:'lg'});
    modalRef.result.then((result:PlanEtudeRomandSearchViewModel) => {
      this.criteria.perId =result.id;
      this.criteria.perObjectiveCode = result.objectiveCode;
    },()=>{});
    
  }
  clone(){
    this.activeModal.dismiss('clone');
  }

}
