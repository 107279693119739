import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Filter } from '@app/model';
import { RouterLink } from '@angular/router';
import { NgFor, NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'nemo-tags-toggler',
    templateUrl: './tags-toggler.component.html',
    styleUrls: ['./tags-toggler.component.css'],
    standalone: true,
    imports: [NgFor, NgIf, NgClass, RouterLink]
})
export class TagsTogglerComponent {
  @Input() title: string;
  @Input() tags: Filter[][];
  @Input() edit = true;
  @Input() addBorder = false;
  @Output() changed = new EventEmitter();

  constructor() { }

  toggle(tag: Filter) {
    this.tags.forEach(cat => {
      cat.forEach(t => {
        if (t.value === tag.value) {
          t.selected = !t.selected;
        }
      });
    });
    this.changed.emit();
  }
}
