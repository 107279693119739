import { Location, NgIf, NgFor } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ListeService } from '@app/core/service';
import { Item, ItemList } from '@app/model';

@Component({
    selector: 'nemo-liste-impression',
    templateUrl: './liste-impression.component.html',
    styleUrls: ['./liste-impression.component.css'],
    standalone: true,
    imports: [NgIf, NgFor]
})
export class ListeImpressionComponent implements OnInit {
  list: ItemList;
  chunkedItems: Item[][];
  chunkedItemsDouble: Item[][];
  doubleSided = false;
  flipped = false;

  constructor(
    private readonly listeservice: ListeService,
    private readonly route: ActivatedRoute,
    private readonly location: Location
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.listeservice.getList(params['id']).subscribe(list => {
        this.list = list;
        this.chunkedItems = this.chunkItems(this.list.items);

        const double = this.list.items.reduce((res, current, index, array) => {
          return res.concat([current, current]);
        }, []);

        this.chunkedItemsDouble = this.chunkItems(double);
      });

    });
  }

  chunkItems(array: Item[]): Item[][]{
    var perChunk = 4;
    let result = array.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index/perChunk)
        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [] // start a new chunk
        }
        resultArray[chunkIndex].push(item);
        return resultArray
      }, []);

      var j =4- result[result.length -1 ].length%4;
      for(let u =0; u< j; u++) {
        result[result.length-1].push({
          id:100
        });
      }
      return result;
  }
  print() {
    window.print();
  }
  back(): void {
    this.location.back();
  }
  flip(): void {
    this.flipped=!this.flipped;
  }
  toggleBoth(): void {
    this.doubleSided = !this.doubleSided;
  }


}
