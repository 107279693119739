import { Location, NgIf, NgClass, NgFor } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MediaService, ToastService, ParcoursService, PreferencesService } from '@app/core/service';
import { Course, CourseItem, CourseRating } from '@app/model';
import { MediaDisplayModalComponent } from '@app/shared/components/media-display-modal/media-display-modal.component';
import { NgbModal, NgbCollapse, NgbRating } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { PlanEtudeRomandDisplayComponent } from '../../../shared/components/plan-etude-romand-display/plan-etude-romand-display.component';
import { ParcoursItemStartComponent } from '../../../shared/components/parcours-item-start/parcours-item-start.component';
import { ClipboardModule } from 'ngx-clipboard';
import { ActionMenuComponent } from '../../../shared/components/action-menu/action-menu.component';
import { CardComponent } from '../../../shared/components/card/card.component';
import { BasepageComponent } from '../../../shared/components/basepage/basepage.component';
import { AuthenticationService } from '@app/core/service/authentication.service';


@Component({
    selector: 'nemo-parcours-detail',
    templateUrl: './parcours-detail.component.html',
    styleUrls: ['./parcours-detail.component.css'],
    standalone: true,
    imports: [NgIf, BasepageComponent, CardComponent, RouterLink, ActionMenuComponent, NgClass, ClipboardModule, NgFor, ParcoursItemStartComponent, NgbCollapse, PlanEtudeRomandDisplayComponent, NgbRating, FormsModule]
})
export class ParcoursDetailComponent implements OnInit {
  public list: Course;
  public displayItems = false;
  public libre = false;
  public rpn = false;
  public ended = false;
  public comment: string;
  public currentRate = 0;
  isCollapsed = true;
  enableAddToCart = false;
  isProposed = false;
  isPersonnal = false;
  navigationThroughRouter=true;
  constructor(
    public router: Router,
    private readonly service: ParcoursService,
    private readonly route: ActivatedRoute,
    private readonly toastService: ToastService,
    private readonly preferencesService: PreferencesService,
    private readonly mediaService: MediaService,
    private readonly modalService: NgbModal,
    private readonly authService: AuthenticationService,
    public readonly location: Location
  ) { }

  ngOnInit() {
    this.route.url.subscribe(url=>{
      this.navigationThroughRouter=!url.find(u=>u.path==='detail');
    });
    this.route.params.subscribe(params => {
      this.service.get(params['id']).subscribe(list => {
        this.list = list;
        this.route.url.subscribe(p=>{
          this.isPersonnal = list.mine && p.findIndex(t=>t.path==='personnels') > -1;
        });
        this.libre = this.list.libre && this.router.url.indexOf('libre') >= 0;
        this.rpn = !this.libre && this.list.rpn;
        this.updateProgression();
        this.displayItems = true;
      });
    });
  }
  clone() {
    const newList = this.list;
    if (this.list.mine) {
      newList.title = this.list.title;
    }
    newList.progression = 0;
    this.service.clone(newList).subscribe(res => {
      this.router.navigate(['/parcours/personnels',res.id,'edition']);
      this.toastService.success('Parcours copié');
    });
  }
  sendbymail() {
    const subject = `Partage de parcours iClasse - ${this.list.title}`;
    const body = `Voici le parcours iClasse ${this.list.title} accessible à l\'url : ${document.location.href} .`;
    window.location.href = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  }

  proposerauSeo(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then(
			(result) => {
        this.service.propose(this.list.id).subscribe((result) => {
          if(result) {
            this.isProposed = true;
            this.toastService.success('Merci pour votre participation !');
          }else {
            this.toastService.error('Vous n\'êtes par l\'auteur de ce parcours !');
          }
        },()=>{
          this.toastService.error('Un problème est survenu !');
        });
			},
			() => {},
		);
  }

  updateProgression() {
    this.list.progression = Math.round(
      (this.list.items.filter(i => i.done).length /
        this.list.items.filter(i=>i.progressionEnabled).length) *
      100
    );

  }
  toggleDisplayItems() {
    this.displayItems = !this.displayItems;
  }

  goBack() {
    this.router.navigate([`${this.router.url}/..`]);
  }
  clearProgression() {
    this.service.clearProgress(this.list.id).subscribe(res => {
      this.list.items.forEach(i => (i.done = false));
      this.updateProgression();
      this.toastService.success('Progression effacée');
    });
  }

  startItem(item: CourseItem) {
    this.service.setCurrentCourseUrl(this.router.url);
    const self = item.navigationUrl && item.navigationUrl.startsWith('/') && !item.navigationUrl.startsWith("/api/v1/medias");
    if (item.itemType !== 'Text' && item.itemType !== 'Title') {
      item.done = true;
    }

    const mediaAction = this.mediaService.detectMediaActionToHandle(item.navigationUrl);
    if (this.authService.getCurrentUserProfile().isAuthenticated) {
      this.service.progress(item.id, item.done).subscribe(() => {
        this.updateProgression();
        if (this.list.progression > 99) {
          this.ended = true;
        }
      });
    }
    if (self) {
      this.router.navigate([item.navigationUrl]);
    } else if (item.navigationUrl) {
      if (mediaAction.image || mediaAction.video || mediaAction.sound) {
        mediaAction.public = this.list.libre;
        const modalRef = this.modalService.open(MediaDisplayModalComponent, {size: 'xl'});
        modalRef.componentInstance.item = item;
        modalRef.componentInstance.mediaAction = mediaAction;
        modalRef.result.then(() => {
        }, () => {
        });
      } else {
        window.open(item.navigationUrl, '_blank');
      }
    }

  }

  back() {
    if(this.navigationThroughRouter){
      this.router.navigate(['../'], { relativeTo:this.route });
    }else{
      this.location.back();
    }
  }

  isTeacher(): boolean {
    return this.preferencesService.isTeacher();
  }
  isOnlyStudent(): boolean {
    return this.preferencesService.isStudent() && !this.preferencesService.isTeacher() &&!this.preferencesService.isAdmin();
  }
  getDocumentLocation() {
    return document.location.href;
  }
  copied(e: any) {
    if (e.isSuccess) {
      this.toastService.info('Copié !');
    }
  }
  rate() {
    this.ended = false;
    this.service.rate(new CourseRating({
      comment: this.comment,
      rating: this.currentRate,
      courseId: this.list.id
    })).subscribe(r => {
      if (r) {
        this.toastService.success('Votre avis nous est bien parvenu, merci !');
      }
    });
  }
  addToCart(): void {
    this.enableAddToCart = ! this.enableAddToCart;
  }
}
