<div *ngIf="autoEvaluationEnabled">
  <button type="button" class="btn btn-sm" (click)="displayAutoEvaluation()"
   [disabled]="!(this.state !== this.selfState && (this.state === 'ok' || this.state === 'nok') && (this.selfState === 'nok' || this.selfState === 'ok'))"
   ngbTooltip="L'élève ne semble pas en accord avec l'avis de l'équipe pédagogique."
   [ngClass]="{
      'btn-light': !(this.state !== this.selfState && (this.state === 'ok' || this.state === 'nok') && (this.selfState === 'nok' || this.selfState === 'ok')) &&  ! (this.state === this.selfState && (this.state === 'ok' || this.state === 'nok') && (this.selfState === 'nok' || this.selfState === 'ok')),
      'btn-outline-info': this.state !== this.selfState && (this.state === 'ok' || this.state === 'nok') && (this.selfState === 'nok' || this.selfState === 'ok') ,
      'btn-light': this.state === this.selfState && (this.state === 'ok' || this.state === 'nok') && (this.selfState === 'nok' || this.selfState === 'ok')
    }">
    <span [ngClass]="{
      'bi bi-person-fill-gear': !(this.state !== this.selfState && (this.state === 'ok' || this.state === 'nok') && (this.selfState === 'nok' || this.selfState === 'ok')) &&  ! (this.state === this.selfState && (this.state === 'ok' || this.state === 'nok') && (this.selfState === 'nok' || this.selfState === 'ok')) ,
      'bi bi-person-fill-add': this.state !== this.selfState && (this.state === 'ok' || this.state === 'nok') && (this.selfState === 'nok' || this.selfState === 'ok') ,
      'bi bi-person-fill-check': this.state === this.selfState && (this.state === 'ok' || this.state === 'nok') && (this.selfState === 'nok' || this.selfState === 'ok')
    }"></span></button>
</div>
