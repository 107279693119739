import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivityService, ListeService } from '@app/core/service';
import { Activity, ActivityList, ItemList, ItemListSummary } from '@app/model';
import { RouterLink } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'nemo-activity-selector',
    templateUrl: './activity-selector.component.html',
    styleUrls: ['./activity-selector.component.css'],
    standalone: true,
    imports: [NgIf, FormsModule, NgFor, RouterLink, NgClass]
})
export class ActivitySelectorComponent implements OnInit {

  @Output() updated = new EventEmitter<ActivityList>();
  lists: ItemListSummary[];
  filteredLists: ItemListSummary[];
  activities: Activity[];
  filter = '';

  selectedList: ItemList;
  selectedActivity: Activity;


  constructor(private readonly listeService: ListeService,
    private readonly activityService: ActivityService) { }

  ngOnInit() {
    this.listeService.getPersonnalLists().subscribe(l => {
      this.lists = [...l]
        .sort((l1: ItemListSummary, l2: ItemListSummary) => new Date(l1.updatedOn) < new Date(l2.updatedOn) ? 0 : -1);
      this.filteredLists = this.lists;
    });
  }

  filterLists(event: any) {
    this.filter = event.target.value;
    this.filteredLists = this.lists.filter(l => l.title.toLocaleLowerCase().search(new RegExp(this.filter.toLocaleLowerCase(), 'i')) > -1);
  }
  clearFilterLists() {
    this.filter = '';
    this.filteredLists = this.lists;
  }

  selectList(list: ItemListSummary) {
    this.listeService.getList(list.id.toString()).subscribe(l => {
      this.selectedList = l;
      this.activities =  this.activityService.getAvailableActivities(this.selectedList);
    });
  }
  selectActivity(activity: Activity) {
    this.selectedActivity = activity;
    this.emitChanges();
  }
  emitChanges() {
    if (this.selectedActivity && this.selectedList) {
      this.updated.emit(new ActivityList({
        activity: this.selectedActivity,
        list: this.selectedList
      }));
    }
  }
}
